<template>
  <div class="mine">
    <mt-header :title="$t('mine')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="bar">
      <img src="@/assets/logo.png">
      <div class="app_name">{{ $t('projectName') }}</div>
      <div class="name">{{ user.name }}</div>
      <div class="phone">{{ user.phone }}</div>
    </div>
    <div class="content">
      <div class="item" @click="$router.push('/mine/orderHistory')">
        <img src="../assets/repayment.png" width="25px" />
        <div>{{ $t('orderHistory') }}</div>
      </div>
      <div class="item" @click="$router.push('/mine/repayRecord')">
        <img src="../assets/repay_record.png" width="25px" />
        <div>Repay Record</div>
      </div>

      <!-- <mt-cell :title="$t('personalInfo')" is-link to="/mine/personalInfo"></mt-cell> -->
      <!-- <mt-cell :title="$t('orderHistory')" is-link to="/mine/orderHistory"></mt-cell>
      <mt-cell :title="$t('repaymentInfo')" is-link to="/mine/repayRecord"></mt-cell> -->
      <!-- <mt-cell :title="$t('inviteFriends')" is-link to="/mine/inviteFriends"></mt-cell> -->
      <!-- <mt-cell :title="$t('discountCoupon')" is-link to="/mine/discountCoupon"></mt-cell> -->
      <!-- <mt-cell :title="$t('myPromotion')" is-link to="/mine/myPromotion"></mt-cell> -->
    </div>
    <div class="bottom">
      <mt-button type="danger" size="large" @click="onLogOut">{{ $t('logOut') }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapMutations, mapState } from "vuex";
export default {
  name: 'Mine',
  components: {
    callPhone
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["SET_ACCESS_TOKEN", "SET_USER"]),
    onLogOut() {
      this.SET_ACCESS_TOKEN();
      this.SET_USER({});
      this.$router.push('/user/login');
    }
  }
}
</script>

<style lang="scss" scoped>
.mine {
  position: relative;
  overflow: hidden;
  background: url(../assets/login_bg.png) no-repeat ;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 600px;
    transform: translateX(-50%);
    left: 50%;
    top: -150px;
    height: 300px;
    z-index: -1;
    background: linear-gradient(to top, #1ED94F, #F2F047, #F2F047, #F2F047);
  }
  &::before {
    content: '';
    position: absolute;
    background: #EAF9DD;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .bar {
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    font-size: 14px;
    border-radius: 20px;
    color: #FFFFFF;
    img {
      height: 83px;
      margin-top: 10px;
    }
    .app_name {
      color: #FFFFFF;
      font-weight: bold;
      margin-top: 10px;
    }
    .name{
      margin-top: 20px;
    }
    .phone{
      margin-top: 10px;
    }
  }
  .content {
    padding-bottom: 20px;
    border-radius: 10px;
    margin: 30px 25px 20px 25px;
    background-color: white;
    overflow: hidden;
    .item {
      width: 50%;
      display: inline-block;
      text-align: center;
      float: left;
      margin-top: 20px;
    }
  }
  .bottom {
    margin: 40px 25px 20px 25px;
    .mint-button{
      color: #EE0B0B;
      height: 50px;
      border-radius: 24px;
    }
  }
}
</style>
