<template>
  <div class="confirm">
    <mt-header :title="$t('confirmOrder')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">{{ $t('loanTips') }}</div>
      <div class="call">
        <div class="title">
          <img src="../../assets/amount.png" width="18px" />
          Loan Amount
        </div>
        <div class="contemt">
          <div class="value">
            {{ Math.min(amountValue, loanProduct.maxAmount) | formatMoney }}
          </div>
          <mt-range
            v-model="amount"
            :min="0"
            :max="100"
            :step="step"
            :bar-height="4"
            @input="onRange">
          </mt-range>
          <div class="info">
            <span>{{ loanProduct.minAmount | formatMoney }}</span>
            <div class="tips" v-if="amountValue > loanProduct.maxAmount">
              {{ $t('moneyMaxTips') }} {{ loanProduct.maxAmount | formatMoney }}
            </div>
            <span>{{ loanProduct.maxViewAmount | formatMoney }}</span>
          </div>
        </div>
      </div>
      <div class="call">
        <div class="title">
          <img src="../../assets/days.png" width="15px" />
          Loan Days
        </div>
        <div class="contemt">
          <div class="value">
            {{ period.loanTerms }} Days
          </div>
          <div class="terms_tips">
            The current product is a <span style="color: #990055">{{loanProduct.instalmentNums}}-period </span> product with <span style="color: #990055">{{loanProduct.loanTermDay}} days </span> per period
          </div>
        </div>
      </div>
      <div class="orderConfirm">
        <img src="../../assets/approval.png">
        {{ $t('orderConfirmation') }}
      </div>
      <div class="trial">
        <mt-cell title="Credit Protection Service">
          ₦ {{ trial.loanInitSvcFee | formatMoney }}
        </mt-cell>
        <mt-cell title="Received">
          ₦ {{ trial.actualAmount | formatMoney }}
        </mt-cell>
        <mt-cell title="Due date">
          {{ trial.loanPmtDueDate}}
        </mt-cell>
        <!-- <mt-cell :title="$t('fee')">
          {{ (trial.totalAmt - trial.actualAmount) | formatMoney }}
        </mt-cell> -->
        <div @click ="popupVisible=true">
          <mt-cell title="RepayAmount" is-link >
            ₦ {{ trial.totalAmt | formatMoney }}
          </mt-cell>
        </div>
      </div>

      <mt-popup class="plan_popup" v-model="popupVisible"  position="bottom" scrollable="true">
        <div class="plan_box">
          <div class="stages" v-if="(trial.instalmentMark || 'N') === 'N'">
            <div class="item" v-for="(item, index) in (trial.schedules || [])" :key="index">
              <div>
                <mt-cell title="Due date">
                  {{ item.loanPmtDueDate}}
                </mt-cell>
                <mt-cell title="Principal">
                  ₦  {{ item.loanInitPrin | formatMoney }}
                </mt-cell>
                <mt-cell title="Interest">
                  ₦  {{ item.interest | formatMoney }}
                </mt-cell>
                <mt-cell title="Credit Protection Service">
                  ₦  {{ item.serviceFees | formatMoney }}
                </mt-cell>
                <mt-cell title="Repayment Amount">
                  ₦  {{ item.totalAmt | formatMoney }}
                </mt-cell>
              </div>
            </div>
          </div>
          <div class="stages" v-if="(trial.instalmentMark || 'N') === 'Y'">
            <div class="item" v-for="(item, index) in (trial.termSchedules || [])" :key="index">
              <div class="term_content">
                <div class="title">Term {{ item.currTerm}}</div>
              </div>
              <div>
                <mt-cell title="Due date">
                  {{ item.loanPmtDueDate}}
                </mt-cell>
                <mt-cell title="Principal">
                  ₦  {{ item.loanTermPrin | formatMoney }}
                </mt-cell>
                <mt-cell title="Interest">
                  ₦  {{ item.loanTermInterest| formatMoney }}
                </mt-cell>
                <mt-cell title="Credit Protection Service">
                  ₦  {{ item.loanTermSvcFee | formatMoney }}
                </mt-cell>
                <mt-cell title="Repayment Amount">
                  ₦  {{ item.totalAmt | formatMoney }}
                </mt-cell>
              </div>
            </div>
          </div>
          <div class="button">
            <mt-button type="primary" size="large" @click="popupVisible=false">{{
                $t("confirm")
              }}</mt-button>
          </div>
        </div>
      </mt-popup>

      <!--
      <div class="stages" v-if="(trial.instalmentMark || 'N') == 'Y'">
        <div class="item" v-for="(item, index) in (trial.termSchedules || [])" :key="index">
          <div class="title">{{ $t('stage') }} {{ index + 1 }}/{{ trial.termSchedules.length }}</div>
          <div>
            <mt-cell :title="$t('repaymentDate')">
              {{ item.loanPmtDueDate }}
            </mt-cell>
            <mt-cell :title="$t('repaymentAmount')">
              <template v-if="item.freeMark == 'Y'">
                <s>{{ item.totalAmt | formatMoney }}</s>
                <div class="free">
                  {{ $t('free') }}
                </div>
              </template>
              <template v-else>
                {{ item.totalAmt | formatMoney }}
              </template>
            </mt-cell>
          </div>
        </div>
      </div> -->

      <div class="show">
        <!--
        <mt-cell v-if="(trial.instalmentMark || 'N') == 'N'" :title="$t('repaymentDate')">
          {{ trial.loanPmtDueDate }}
        </mt-cell>
        <template v-if="coupon.length">
          <mt-cell :title="$t('amountPayable')">
            {{ trial.totalAmt | formatMoney }}
          </mt-cell>
          <mt-cell v-if="coupon.length == 1" :title="$t('repaymentReliefReward')">
            {{ couponItem.amount | formatMoney }}
          </mt-cell>
          <mt-cell
            v-else
            :title="$t('repaymentReliefReward')"
            v-model="(couponList.filter(e => e.id == couponItem.id)[0]||{}).name"
            @click.native="onPopupPicker(couponList, (e) => {
              couponItem = e;
              BEHAVIOR_ADD({
                id: 'P06_C04_I_GENDER',
                newValue: e.amount
              })
            })"
          >
            {{ couponItem.label }}
            <i class="iconfont">&#xe62a;</i>
          </mt-cell>
        </template>
        <template>
          <mt-cell :title="$t('repaymentAmount')">
            {{ (trial.totalAmt - (couponItem.amount || 0)) | formatMoney }}
          </mt-cell>
        </template> -->
        <mt-cell :title="$t('bankCardNumber')">
          {{ bankCardNo }}
        </mt-cell>
        <mt-cell :title="$t('clickModifyBankCard')" is-link to="/step/modifyBankInfo">
          <img slot="icon" class="bankCard" src="../../assets/bankCard.png">
        </mt-cell>
      </div>
    </div>
    <mt-cell class="loanAgreementBox">
      <label class="mint-checklist-label" slot="title">
        <span class="mint-checkbox">
          <input class="mint-checkbox-input" type="checkbox" v-model="currentValue" :value="1">
          <span class="mint-checkbox-core"></span>
        </span>
        <div class="loanAgreement">
          {{ $t('clickingAgreeOur') }}
          <span @click.stop.prevent="onLoanAgreement">
            <b>{{ $t('loanAgreement') }}</b>
          </span>
        </div>
      </label>
    </mt-cell>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit" :disabled="!currentValue">Submit</mt-button>
    </div>

    <div class="ma_shade" v-if="popupVisibleCoupon">
      <div class="ma_shade_bg" @click="popupVisibleCoupon = false"/>
      <div class="ma_shade_box coupon_box">
        <div class="coupon_content">
          <img class="couponBg" src="../../assets/coupon_bg.png">
          <div class="coupon_logo">
            <img src="../../assets/login_img_1.png">
          </div>
          <div class="coupon_title">{{ $t('discountTitle') }}</div>
          <div class="coupon_tip_box">
            <div class="coupon_tip1">{{ $t('discountTips') }}</div>
            <div class="coupon_tip2">{{couponItem.amount | formatMoney}}</div>
            <div class="coupon_tip3">{{couponItem.expireDate}} {{ $t('overdue') }}</div>
          </div>
          <div class="coupon_button" @click="popupVisibleCoupon = false">
            <span>{{ $t('useImmediately') }}</span>
            <div class="coupon_button_next" />
          </div>
        </div>
        <div class="coupon_close" @click="popupVisibleCoupon = false"/>
      </div>
    </div>

    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { product, scheduleCalc, step, myCoupon, useMyCoupon, getUserInfo } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import popupPicker from '@/components/popupPicker.vue'
import branch from '../../../node_modules/branch-sdk'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
import Vue from 'vue'
export default {
  name: 'Confirm',
  components: {
    callPhone,
    popupPicker,
  },
  data() {
    return {
      currentValue: true,
      amount: 0,
      oldAmount: 0,
      period: {},
      product: {},
      loanProduct:{},
      trial: {},
      bankCardNo: '',
      // 红包
      coupon: [],
      popupVisibleCoupon: false,
      popupVisible: false,
      couponItem: {},
    }
  },
  computed: {
    ...mapState(["user", "brand"]),
    step() {
      return 100 / ((this.loanProduct.maxViewAmount - this.loanProduct.minAmount) / this.product.amountStep)
    },
    amountValue() {
      return (Math.round(this.amount / 100 * (this.loanProduct.maxViewAmount - this.loanProduct.minAmount) / this.loanProduct.amountStep) * this.loanProduct.amountStep) + this.loanProduct.minAmount
      // return  this.loanProduct.maxAmount
    },
    trialVal() {
      return `${this.amountValue} ${this.period.loanTerms}`
    },
    couponList() {
      return this.coupon.map(e => ({
        ...e,
        label: `${e.name} ${this.$options.filters.formatMoney(e.amount)}`
      }))
    }
  },
  watch: {
    trialVal() {
      this.getTrial()
    },
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P06_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P06_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getProduct(),
        // this.getMyCoupon(),
        this.getUser(),
      ])
      this.$indicator.close();
    },
    onLoanAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.serviceUrl)
      }
    },
    onRange(val) {
      if (this.oldAmount !== val) {
        this.BEHAVIOR_ADD({
          id: 'P06_C01_S_LOANAMT',
          newValue: val
        })
        this.oldAmount = val
      }
      this.amount = val
    },
    // 打开弹窗
    onPopupPicker(options, callback) {
      this.$refs.popupPicker.openPicker({
        label: 'label',
        options,
        callback
      })
    },
    addStar(val, front, after){
      return val.substring(0,front)+'*'.repeat(val.length-(front+after))+val.substring(val.length-after)
    },
    // 获取未使用的优惠卷
    async getMyCoupon() {
      await this.$axios({
        method: "get",
        url: myCoupon,
        params: {
          status: '0'
        }
      })
        .then(async (e) => {
          if (e.status.code === "000" && e.body.length) {
            this.coupon = e.body
            this.$nextTick(() => {
              this.$set(this, 'couponItem', this.couponList[0])
            })
            this.popupVisibleCoupon = true
          }
        })
        .catch(() => {});
    },
    // 获取产品
    async getProduct() {
      await this.$axios({
        method: "POST",
        url: product,
        data: {}
      })
        .then((e) => {
          if (e.status.code === "000") {
            this.product = e.body
            console.log(this.product)
            this.loanProduct = this.product.products[0]
            if(this.loanProduct.loanType === 'MCEI') {
              this.loanProduct.maxAmount = this.product.mceiMaxAmount
              this.loanProduct.minAmount = this.product.mceiMinAmount
              this.loanProduct.maxViewAmount = this.product.maxViewAmount
              this.loanProduct.amountStep = this.product.mceiAmountStep
            } else {
              this.loanProduct.maxAmount = this.product.maxAmount
              this.loanProduct.minAmount = this.product.minAmount
              this.loanProduct.maxViewAmount = this.product.maxViewAmount
              this.loanProduct.amountStep = this.product.amountStep
            }
            console.log(this.loanProduct)
            this.amount = (this.loanProduct.maxAmount - this.loanProduct.minAmount) / (this.loanProduct.maxViewAmount - this.loanProduct.minAmount) * 100
            console.log(this.amount)
            this.period = (e.body.products||[]).sort((a, b) => a.loanTerms - b.loanTerms)[0]||{}
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
    },
    // 获取试算
    async getTrial() {
      await this.$axios({
        method: "post",
        url: scheduleCalc,
        data: {
          // loanAmt: Math.min(this.amountValue, this.loanProduct.maxAmount),
          loanAmt: this.loanProduct.maxAmount,
          loanDay: this.loanProduct.loanTerms,
          loanCode: this.loanProduct.loanCode,
          productCode: this.loanProduct.productCode,
          displayLoanDays: this.loanProduct.displayLoanDays,
          instalmentNums:this.loanProduct.instalmentNums,
          loanType:this.loanProduct.loanType
        }
      })
        .then((e) => {
          if (e.status.code === "000") {
            this.trial = e.body || {}
            console.log(this.trial)
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === "000") {
          this.SET_USER(e.body);
          this.bankCardNo = this.addStar(e.body.customerDto.bankCardNo, 4, 4)
        }
      }).catch(() => {})
    },
    // 使用优惠卷
    async onUseMyCoupon() {
      await this.$axios({
        method: "post",
        url: useMyCoupon + '?couponId=' + this.couponItem.id,
        params: {
          confirm: 'Y',
        }
      })
        .then(() => {})
        .catch(() => {});
    },
    // 签约
    async submit() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 根据是否已结清来判断是否是续贷或首贷
        if (this.user.applyStatus !== 'SETTLE') {
          let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
          if (pixel) {
            Vue.analytics.fbq.event('07_CONFIRM', { content_name: '07_CONFIRM' })
          }
          // Google Analytics  FIRST_CONFIRM
          if (localStorage['ga']) {
            window.gtag('event', 'FIRST_CONFIRM')
          }
        } else {
          let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
          if (pixel) {
            Vue.analytics.fbq.event('12_CONTINUED_CONFIRM', { content_name: '12_CONTINUED_CONFIRM' })
          }
          // Google Analytics  CONTINUED_CONFIRM
          if (localStorage['ga']) {
            window.gtag('event', 'CONTINUED_CONFIRM')
          }
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),
        autoRepayment: true,

        applyId: this.user.applyId,
        currentStep: 7,
        totalSteps: 7,
        maxApplyAmount: this.loanProduct.maxAmount,
        applyAmount: Math.min(this.amountValue, this.loanProduct.maxAmount),
        loanTerms: this.period.loanTerms,
        loanCode: this.period.loanCode,
        displayLoanDays: this.period.displayLoanDays,
        loanType:this.loanProduct.loanType,
        instalmentNums:this.loanProduct.instalmentNums,
        loanTermDay:this.loanProduct.loanTermDay,
        instalmentFlag:this.loanProduct.loanType,
        loanTermNum:this.loanProduct.instalmentNums,
      }
      await this.$axios({
        method: "post",
        url: step,
        params: { applyId: this.user.applyId },
        data: data,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            await this.$router.push('/apply/done')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('12_CONTINUED_CONFIRM', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm {
  min-height: 100vh;
  background: url(../../assets/login_bg.png) no-repeat ;
  background-size: cover;
  .content {
    .tips {
      padding: 20px;
      font-size: 12px;
      color: #3F59B8;
    }
    .terms_tips{
      margin: 10px;
      font-size: 12px;
      font-weight: 700;
      color: #3F59B8;
    }
    .plan_popup{
      min-height: 300px;
      max-height: 80vh;
      margin-top: 50px;
      width: 100%;
      border-radius: 30px;
      overflow-y: auto; /* 使内容可滑动 */
      .button{
        width: 80%;
        height: 50px;
        margin: 0 auto;
        background-color: #FFFFFF;
        position: sticky;
        /*黏性定位*/
        bottom: 0;
        /*具体父级元素吸附的距离*/
      }
      .term_content{
        display: flex;
        flex-grow: 2;
        .title{
          width: 50%;
          float: left;
        }
        .checkbox{
          float: right;
          width: 50%;
          margin-right: 10px;
          text-align: right;
        }
      }
    }
    .orderConfirm {
      margin:  10px 20px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      img {
        font-size: 0;
        width: 16px;
        vertical-align: sub;
      }
    }
    .call {
      padding: 10px 20px;
      .title {
        padding-bottom: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        img {
          vertical-align: middle;
        }
        span {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .contemt {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        padding: 10px;
        .value {
          font-size: 30px;
          color: #3EDC4E;
          text-align: center;
          font-weight: bold;
        }
        /deep/ .mt-range-progress {
          background: linear-gradient(90deg, #F2F047, #1ED94F);
        }
        /deep/ .mt-range-thumb {
          background: url(../../assets/scale.png);
          background-size: 100%;
          top: -5px;
          height: 40px;
          box-shadow: none;
        }
        .box {
          margin: 5px;
          display: inline-block;
          position: relative;
          .lock {
            position: absolute;
            height: 25px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
          }
          .mint-button {
            color: #000;
          }
          .mint-button--primary {
            color: white;
          }
        }
      }
      .info {
        padding: 10px 0px;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        .tips {
          margin: 0px 10px;
          font-size: 14px;
          border: 1px solid #ccc;
          padding: 5px 10px;
          border-radius: 5px;
          background-color: #ffef94;
          flex: 1;
        }
        .content {
          flex: 1;
        }
      }
    }
    .mint-cell {
      background: #FFFFFF;
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    .trial {
      border-radius: 5px;
      margin: 0 20px;
      display: block;
      padding: 1px;
      
    }
    .show {
      padding: 20px;
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
    }
  }
  .loanAgreementBox {
    background: transparent;
    margin-top: 10px;
    font-size: 12px;
    color: #048130;
    /deep/ .mint-cell-wrapper {
      background-image: none;
    }
  }
  .bankCard {
    width: 24px;
  }
  .mint-checklist-label {
    position: relative;
    .mint-checkbox {
      position: absolute;
    }
    .loanAgreement {
      display: inline-block;
      padding: 0 10px 0 30px;
    }
  }
  .stages {
    .item {
      box-shadow: 0px 0px 2px 0px rgba(34, 35, 40, 0.22);
      margin: 10px 20px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
  }
  .ma_shade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 19891020;
    .ma_shade_bg {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0);
      opacity: 0.8;
    }
    .ma_shade_box {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 1px 1px 50px rgba(0,0,0,.3);
      .ma_shade_box_top {
        width: 100%;
        padding-left: 20px;
        background-color: #F8F8F8;
        height: 42px;
        line-height: 42px;
        border-bottom: 1px solid #eee;
        font-size: 14px;
        color: #333;
      }
      .ma_shade_box_content {
        overflow: auto;
        padding: 20px 20px 10px 20px;
        line-height: 22px;
        font-size: 14px;
        img {
          width: 100%;
          display: block;
          margin: 10px auto;
        }
      }
      .ma_shade_box_bottom {
        width: 100%;
        border-top: 1px solid #eee;
        height: 42px;
        line-height: 42px;
        text-align: center;
        .btn {
          margin: 6px auto;
          font-weight: 500;
          border: 0;
          padding: 0 20px;
          line-height: 30px;
          background:  linear-gradient(349.78deg, #F6C144 0%, #F6C144 100%);
          font-weight: bold;
          color: white;
          text-align: center;
          border-radius: 6px;
          display: block;
          text-decoration: none;
        }
      }
    }
    .coupon_box {
      width: initial;
      background-color: transparent;
      box-shadow: initial;
      .coupon_content {
        position: relative;
        .coupon_logo {
          position: absolute;
          top: 35px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0 auto;
          height: 50px;
          background: white;
          border-radius: 100%;
          padding: 5px 0;
          text-align: center;
          box-shadow: 1px 1px 10px rgba(0,0,0,.3);
          img {
            height: 100%;
          }
        }
        .coupon_title {
          position: absolute;
          top: 130px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          font-size: 22px;
          font-family: Arial;
          font-weight: bold;
          color: #FFFFFF;
          text-shadow: 0px 2px 8px rgba(185, 42, 38, 0.35);
          background: linear-gradient(0deg, #FFD5AE 0%, #FFF5D9 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .coupon_tip_box {
          position: absolute;
          top: 200px;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
        }
        .coupon_tip1 {
          font-size: 15px;
          font-weight: bold;
          color: #FF0000;
        }
        .coupon_tip2 {
          font-size: 32px;
          line-height: 36px;
          font-weight: bold;
          color: #FF0000;
          background: linear-gradient(0deg, #E4283B 14.208984375%, #F7425A 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .coupon_tip3 {
          font-size: 12px;
          color: #FF0000;
        }
        .coupon_button {
          position: absolute;
          bottom: 20px;
          height: 44px;
          line-height: 44px;
          left: 0;
          right: 0;
          text-align: center;
          background: #ea2940;
          border-radius: 30px;
          margin: 0 80px;
          box-shadow: inset 0px 2px 8px rgba(234, 234, 234, 0.35);
          span {
            font-size: 14px;
            font-weight: bold;
            color: #F4E1C5;
            text-shadow: 0px 2px 8px rgba(185, 42, 38, 0.35);
            background: linear-gradient(0deg, #FFD5AE 0%, #FFF5D9 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            vertical-align: super;
          }
          .coupon_button_next {
            width: 21px;
            height: 21px;
            background: url("../../assets/next.png") no-repeat;
            background-size: 100%;
            border-radius: 100%;
            display: inline-block;
            margin: 12px 0  0 5px;
          }
        }
      }
      .coupon_close {
        width: 35px;
        height: 35px;
        background: url("../../assets/close.png") no-repeat;
        background-size: 100%;
        margin: 10px auto;
      }
    }
    .couponBg {
      width: 315px;
      height: 368px;
    }
  }

  .iconfont {
    margin: 0 5px;
    color: black;
  }
}
</style>