<template>
  <img :src="require('@/'+ icon)" class="icon" @click="onCall">
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    icon: {
      type: String,
      default: 'assets/hotline.png'
    }
  },
  computed: {
    ...mapState(['brand'])
  },
  methods: {
    onCall() {
      if (this.brand.serviceInfo) {
        window.location.href = `tel://${this.brand.serviceInfo.ccphone}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
}
</style>
