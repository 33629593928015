<template>
  <div class="idcardauth">
    <mt-header :title="$t('idcardauth')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="5" />
    <div class="content">
      <upload-image ref="uploadImage" v-model="images" behavior="P05" />
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { step } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import uploadImage from './UploadImage.vue'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
export default {
  name: 'Idcardauth',
  components: {
    uploadImage,
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      images: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P05_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P05_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {

    },
    // 提交下一步
    async submit() {
      var uploadImageError = this.$refs.uploadImage.validation()
      if (uploadImageError) return this.$toast(uploadImageError)
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('05_IDCARD_AUTH', { content_name: '05_IDCARD_AUTH' })
        }
        // Google Analytics  IDCARD_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'IDCARD_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        authName: "IDCARD_AUTH",
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),

        needIdCard: 'Y',

        applyId: this.user.applyId,
        currentStep: 6,
        totalSteps: 7,
        images: this.images,
      }
      await this.$axios({
        method: "post",
        url: step,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('05_IDCARD_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.idcardauth {
  .content {
    .title {
      font-size: 14px;
      color: #048130;
      padding: 0px 20px;
    }
  }
  .bottom {
    padding: 40px 20px 20px 20px;
  }
}
</style>