<template>
  <div class="container">
    <div class="Login">
      <mt-header :title="$t('login')">
        <call-phone slot="right" />
      </mt-header>
      <div class="logo">
        <img src="../../assets/logo.png" width="80px">
        <div>{{ $t('projectName') }}</div>
      </div>
      <div class="content">
        <div class="label">{{ $t('phone') }}</div>
        <mt-field
            :placeholder="$t('phonePlaceholder')"
            v-model="form.phone"
            type="tel"
            v-mask="'#### #### #### #'"
            :state="phoneState"
        ></mt-field>
        <template v-if="isPwd">
          <div class="label">{{ $t('password') }}</div>
          <mt-field
              :placeholder="$t('passwordPlaceholder')"
              :type="!visible? 'password': 'text'"
              v-model="form.password"
              :state="passwordState"
          >
            <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
            <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
          </mt-field>
        </template>
        <template v-else>
          <div class="label">{{ $t('validateCode') }}</div>
          <mt-field v-model="form.validateCode" :state="validateCodeState">
            <mt-button type="primary" size="small" plain :disabled="!!time" @click="getSmsCode('LOGIN')">{{
                $t("smsCode")
              }}{{ time? `(${time})`: '' }}</mt-button>
          </mt-field>
          <mt-cell>
            <span style="margin-right: 5px;">{{ $t("youCanTry") }}</span>
            <mt-button size="small" @click="getIvrCode('LOGIN')" :disabled="!!time">{{ $t("getIvrCode") }}{{ time? `(${time})`: '' }}</mt-button>
          </mt-cell>
        </template>
        <div v-if="isPwd" class="tips" @click="isPwd = false">{{ $t('loginTips1') }}</div>
        <div v-else class="tips" @click="isPwd = true">{{ $t('loginTips2') }}</div>
        <mt-button type="primary" size="large" @click="submit">{{
            $t("login")
          }}</mt-button>
      </div>

      <div class="bottom">
        <!-- <div @click="onRegister">{{ $t("register") }}</div> -->
        <div @click="onPassword">{{ $t("forgetPassword") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { phoneRules, validateCodeRules, passwordRules } from "../../utils/rules";
import { mapMutations } from "vuex";
import { session } from '../../utils/api'
import getLocation from '../../utils/gps'
import mixin from './mixin'
export default {
  name: "Login",
  mixins: [mixin],
  components: {
    callPhone
  },
  data() {
    return {
      isPwd: true,
      visible: false,
    };
  },
  computed: {
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
  },
  watch: {
    
  },
  created() {
    this.form.phone = this.record.loginPhone || "";
    this.form.password = this.record.loginPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onPassword() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/password')
    },
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register')
    },
    async submit() {
      this.isCheck = true;
      let error, data;
      if (this.isPwd) {
        error = this.validation(['phone', 'password']);
        if (error) return this.$toast(error);
      } else {
        error = this.validation(['phone', 'validateCode']);
        if (error) return this.$toast(error);
      }
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      if (this.isPwd) {
        data = {
          loginType: "PIN_LOGIN",
          phone: this.form.phone.replace(/\s/g, ""),
          pin: this.form.password,
          deviceId: "H5",
          gps: await getLocation(),
        }
      } else {
        data = {
          loginType: "CODE_LOGIN",
          phone: this.form.phone.replace(/\s/g, ""),
          code: this.form.validateCode,
          password: '',
          deviceId: "H5",
          isValidateCode: "Y",
          gps: await getLocation(),
        }
      }
      await this.$axios({
        method: "post",
        url: session,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
            await this.getUser();
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
    },
  },
};
</script>

<style scoped lang="scss">
 .container{
   background: url(../../assets/login_bg.png) no-repeat ;
   background-size: cover;
 }
.Login {
  min-height: 100vh;
  // background-color: #2ADA4F;
  .tips {
    text-align: center;
    color: #19BF32;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .visible {
    margin-left: 5px;
  }
  .logo {
    text-align: center;
    margin-top: 60px;
    > div{
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 16px;
    }
  }
  .content {
    margin-left: 15px;
    margin-right: 15px;
    background-color: white;
    padding: 12px;
    border-radius: 10px;
    margin-top: 111px;
    padding-top: 40px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      // background: url(../../assets/login_head.png) no-repeat;
      background-size: cover;
      width: 200px;
      height: 150px;
      top: -111px;
      left: 50%;
      transform: translateX(-50%);
    }
    .label {
      color: #000029;
      font-size: 15px;
      padding: 10px 0px;
    }
    .mint-field {
      border-radius: 3px;
      &::before {
        content: '';
        position: absolute;
        width: 2.5px;
        height: 30px;
        top: 9px;
        background-color: #44DD4E;
      }
      /deep/ .mint-cell-wrapper {
        background-image: none;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    color: white;
  }
}
</style>
