<template>
  <div class="orderHistory">
    <mt-header :title="$t('orderHistory')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <mt-cell v-for="(item, index) in list" :key="index" :title=" 'Loan amount: ₦ ' + item.applyAmount" :label="'Start Date: ' + item.applyDate" is-link :to="`/apply/loanDetail?contractNo=${item.applyId}`">
        <div>{{ item.contractStatusName }}</div>
      </mt-cell>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractList } from '../../utils/api'
export default {
  name: 'OrderHistory',
  components: {
    callPhone
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.loadMore()
      this.$indicator.close();
    },
    async loadMore() {
      await this.$axios({
        method: 'post',
        url: contractList,
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.list = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.orderHistory {
  background: url(../../assets/login_bg.png) no-repeat ;
  background-size: cover;
  min-height: 100vh;
  .mint-cell{
    background-color: #FFFFFF;
    margin: 40px 25px 20px 25px;
    min-height: 80px;
    border-radius: 20px;
  }
  /deep/ .mint-cell-label{
    margin-top: 10px;
  }
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    padding: 10px;
    li {
      display: block;
      padding: 10px 15px 0 15px;
      text-decoration: none;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      margin: 10px 0px;
    }
    .wrap {
      // background: #fff;
      border-radius: 10px;
      .name {
        color: #757575;
        font-size: 12px;
      }
      .val {
        color: #000;
        font-size: 14px;
        color: #383838;
      }
    }
    .title {
      img {
        width: 16px;
        margin: 0 5px;
      }
    }
    .row {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>