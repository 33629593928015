<template>
  <div class="repaymentInfo">
    <mt-header :title="$t('repaymentInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <mt-navbar v-model="selected">
      <mt-tab-item id="1">Web Page Payment</mt-tab-item>
      <mt-tab-item id="2">Bank Transfer</mt-tab-item>
    </mt-navbar>
    <mt-tab-container :value="selected">
      <mt-tab-container-item id="1">
        <div class="bar">
          <div class="title">{{ $t('repaymentAmount') }}</div>
          <mt-button plain >{{ amount | formatMoney }}</mt-button>
          <div class="tips">{{ $t('repaymentTips') }}</div>
        </div>
        <div class="message">
          <div>{{ $t('repaymentMessage') }}</div>
          <div>{{ $t('repaymentMessage2') }}</div>
        </div>
        <div class="content" @click="submit">
          <mt-cell :title="$t('repayment')" is-link>
            <img slot="icon" class="bank" src="../../assets/bank.png">
          </mt-cell>
        </div>
      </mt-tab-container-item>
      <mt-tab-container-item id="2">
        <div class="message">
          <div>{{ $t('repaymentMessage') }}</div>
          <div>{{ $t('repaymentMessage2') }}</div>
        </div>
        <div class="bank_item" v-for="(item, index) in bankAccount" :key="index">
          <mt-cell :title="$t('accountName')" :value="item.accountName"></mt-cell>
          <mt-cell :title="$t('bankName')" :value="item.bank"></mt-cell>
          <mt-cell :title="$t('bankAccount')">
            {{ item.accountNo }}
            <mt-button @click="onCopy(item.accountNo)" class="copy" size="small" type="primary">{{ $t('copy') }}</mt-button>
          </mt-cell>
          <mt-cell :title="$t('repaymentRemark')" :value="item.remark"></mt-cell>
        </div>
      </mt-tab-container-item>
    </mt-tab-container>

    <mt-popup
      v-model="popupVisible"
    >
      <div class="box">
        <div class="title">{{ $t('remainingAmountDue') }} {{ item.payAmount | formatMoney }}</div>
        <div class="tips">{{ $t('applicationRepayment') }}</div>
        <div class="content">
          <mt-field
            :label="$t('amount')"
            :state="amountState"
            :placeholder="$t('amountPlaceholder')"
            v-model="amount"
          ></mt-field>
        </div>
        <div class="button">
          <mt-button type="primary" size="large" @click="submit">{{
            $t("confirm")
          }}</mt-button>
          <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>

    <mt-popup v-model="popupNullVisible" :closeOnClickModal="false">
      <div class="box">
        <div class="title">{{ $t('notHaveLoan') }}</div>
        <div class="button">
          <mt-button type="primary" size="large" @click="popupNullVisible = false; $router.go(-1)">{{
            $t("getMoneyNow")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractDetail, getPayPlatAddress, getUserInfo, getRepayBankAccount } from '../../utils/api'
export default {
  name: 'RepaymentInfo',
  components: {
    callPhone
  },
  data() {
    return {
      popupVisible: false,
      popupNullVisible: false,
      amount: '0',
      item: {},
      selected: '1',
      bankAccount: [],
    }
  },
  computed: {
    ...mapState(["user"]),
    amountState() {
      if (this.amount > this.item.payAmount || this.amount <= 0) {
        return 'error'
      }
      return 'success'
    },
    applyId() {
      return this.$route.query.applyId ?? this.user.applyId;
    },
    repayAmount() {
      return this.$route.query.amount
    },
  },
  watch: {
    popupVisible(val) {
      if (val) {
        this.amount = this.item.payAmount
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getContractDetail(),
        this.getUser(),
        this.getRepayBankAccount(),
      ])
      this.$indicator.close();
      this.amount = this.repayAmount
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.applyId
        }
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
    async getRepayBankAccount() {
      await this.$axios({
        method: 'post',
        url: getRepayBankAccount,
      }).then((e) => {
        if (e.status.code === "000") {
          this.bankAccount = e.body.accountList;
        }
      }).catch(() => {})
    },
    async submit() {
      if (this.amountState !== 'success') {
        return this.$toast(this.$t('amountPlaceholder'));
      }
      this.popupVisible = false
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: 'post',
        url: getPayPlatAddress,
        data: {
          applyId: this.applyId,
          amount: parseFloat(this.amount),
        }
      })
        .then((e) => {
          if (e.status.code === '000') {
            window.location.href = e.body.webUrl
          }
        })
        .catch(() => {})
      this.$indicator.close();
    },
    onCopy(value) {
      let transfer = document.createElement('input');
      document.body.appendChild(transfer);
      transfer.readonly = 'readonly';
      transfer.value = value;
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      this.$toast(this.$t('copySuccess'));
      document.body.removeChild(transfer);
    }
  }
}
</script>

<style lang="scss" scoped>
.repaymentInfo {
  min-height: 100vh;
  background: url(../../assets/login_bg.png) no-repeat ;
  background-size: cover;
  .copy {
    margin-left: 5px;
  }
  .content{
    margin: 10px 25px 20px 25px;
  }
  .bar {
    padding-bottom: 20px;
    background-image: linear-gradient(to top, rgba(242, 240, 71, 0.61), rgba(30, 217, 79, 0.61));
    text-align: center;
    color: white;
    .title {
      padding-top: 10px;
    }
    .is-plain {
      color: white;
      border: 1px solid white;
      min-width: 200px;
      margin-top: 10px;
    }
    .tips {
      font-size: 12px;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .message {
    padding: 10px;
    font-size: 12px;
    div {
      padding-top: 5px;
    }
  }
  .bank {
    width: 16px;
    margin: 0 5px;
  }
  .bank_item {
    margin: 20px 10px;
    border-right: 1px solid #E5E5E5;
  }

  /deep/ .mint-tab-item-label{
    font-size: 14px;
  }

  /deep/ .mint-popup {
    .box {
      min-width: 300px;
      padding: 10px;
      .title {
        font-weight: 600;
        padding-bottom: 10px;
      }
      .tips {
        font-size: 12px;
        padding: 5px;
      }
      .button {
        padding-top: 20px;
        display: flex;
        button {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
